import React, { useEffect, useState } from "react";
import {
  Card,
  Form,
  Row,
  Col,
  Accordion,
  InputGroup,
  Button,
  Spinner,
} from "react-bootstrap";
import { SketchPicker } from "react-color";
import { Settings, Layers, Type, FileText, Users } from "lucide-react";
import { API, AXIOS_METHOD_TYPES } from "../../../constants/api";
import Swal from "sweetalert2";
import { withAPIRequest } from "../../../HOC/withAPIRequest";
import { useSelector } from "react-redux";

const fieldsValues = {
  firstName: true,
  lastName: true,
  fatherName: true,
  motherName: true,
  dateOfBirth: true,
  dateOfBaptism: true,
  fatherNationality: true,
  gender: true,
  fatherDomicile: true,
  fatherProfession: true,
  godFatherName: true,
  godFatherLastName: true,
  godFatherDomicile: true,
  godMotherName: true,
  godMotherLastName: true,
  godMotherDomicile: true,
  placeOfBaptism: true,
  ministerName: true,
  holyCommunion: true,
  confirmation: true,
  marriage: true,
  remark: true,
};

const BaptismConfigs = ({ commonAPIRequest, churchId, baptismConfig }) => {
  const [loading, setLoading] = useState(false);

  const [config, setConfig] = useState({
    backgroundColor: "#fff",
    letterhead: 1,
    border: {
      show: true,
      outerBorderColor: "#fff",
      innerborderColor: "#fff",
    },
    fieldsStyles: {
      labelFontSize: 12,
      valueFontSize: 12,
      labelColor: "#fff",
      valueColor: "#fff",
    },
    watermarkOpacity: 1,
    fields: {
      firstName: true,
      lastName: true,
      fatherName: true,
      motherName: true,
      dateOfBirth: true,
      dateOfBaptism: true,
      fatherNationality: true,
      gender: true,
      fatherDomicile: true,
      fatherProfession: true,
      godFatherName: true,
      godFatherLastName: true,
      godFatherDomicile: true,
      godMotherName: true,
      godMotherLastName: true,
      godMotherDomicile: true,
      placeOfBaptism: true,
      ministerName: true,
      holyCommunion: true,
      confirmation: true,
      marriage: true,
      remark: true,
    },
  });

  const [activeColorPicker, setActiveColorPicker] = useState(null);

  const handleColorChange = (color, field) => {
    setConfig((prev) => {
      if (field.includes(".")) {
        const [section, key] = field.split(".");
        return {
          ...prev,
          [section]: {
            ...prev[section],
            [key]: color.hex,
          },
        };
      }
      return {
        ...prev,
        [field]: color.hex,
      };
    });
  };

  const ColorPickerButton = ({ color, onClick, label }) => (
    <div className="d-flex align-items-center">
      <span className="me-2">{label}:</span>
      <div
        className="color-preview"
        style={{
          backgroundColor: color,
          width: "36px",
          height: "36px",
          border: "2px solid #dee2e6",
          borderRadius: "4px",
          cursor: "pointer",
          transition: "transform 0.2s",
        }}
        onClick={onClick}
      />
      <span className="ms-2 text-muted">{color}</span>
    </div>
  );

  const handleFieldToggle = (fieldName) => {
    setConfig((prev) => {
      const updatedFields = {
        ...prev.fields,
        [fieldName]: !prev.fields[fieldName],
      };
      return {
        ...prev,
        fields: updatedFields,
      };
    });
  };

  const handleStyleChange = (value, field) => {
    setConfig((prev) => ({
      ...prev,
      fieldsStyles: {
        ...prev.fieldsStyles,
        [field]: value,
      },
    }));
  };

  //   Add Baptism ConFig

  const saveBaptismConfigs = () => {
    const serviceParams = {
      api: API.ADD_CONFIGURATION_API,
      method: AXIOS_METHOD_TYPES.POST,
      data: {
        configurationDetail: {
          name: "BAPTISM_CONFIGURATIONS",
          value: config,
          category: "FRONTEND",
        },
        churchId: churchId,
      },
    };

    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          Swal.fire(
            "Success",
            "Certificate Configurations Saved Successfully.",
            "success"
          );
        } catch (error) {
          console.log("error", error);
          Swal.fire("Error", "Failed to save configurations.", "error");
        }
      } else {
        setLoading(false);
        Swal.fire("Error", "Failed to save configurations.", "error");
      }
    });
  };

  //   Add Baptism ConFig

  //   Update Baptism Config

  const updateBaptismConfigs = () => {
    const serviceParams = {
      api: API.ADD_CONFIGURATION_API,
      method: AXIOS_METHOD_TYPES.PUT,
      data: {
        configurationDetail: {
          configId: 4,
          name: "BAPTISM_CONFIGURATIONS",
          value: config,
          category: "FRONTEND",
        },
        // churchId: churchDetails?.churchId,
      },
    };

    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          Swal.fire(
            "Success",
            "Certificate Configurations Saved Successfully.",
            "success"
          );
        } catch (error) {
          console.log("error", error);
          Swal.fire("Error", "Failed to save configurations.", "error");
        }
      } else {
        setLoading(false);
        Swal.fire("Error", "Failed to save configurations.", "error");
      }
    });
  };

  //   Update Baptism Config

  console.log("baptismConfig", baptismConfig);

  useEffect(() => {
    if (baptismConfig?.configId) {
      setConfig((prevConfig) => ({
        ...prevConfig,
        ...baptismConfig.value,
        fields: {
          ...prevConfig.fields,
          ...baptismConfig.value.fields,
        },
      }));
    }
  }, [baptismConfig]);

  return (
    <div className="p-4">
      <h4 className="mb-4 d-flex align-items-center">
        <Settings className="me-2" />
        Baptism Certificate Configurations
      </h4>

      <Accordion className="shadow-sm">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <Layers className="me-2" />
            Layout Settings
          </Accordion.Header>
          <Accordion.Body>
            <Row className="g-3">
              <Col md={12}>
                <ColorPickerButton
                  color={config.backgroundColor}
                  onClick={() => setActiveColorPicker("backgroundColor")}
                  label="Background Color"
                />
              </Col>
              <Col md={12}>
                <Form.Group>
                  <Form.Label>Letterhead Style</Form.Label>
                  <Form.Select
                    value={config.letterhead}
                    onChange={(e) =>
                      setConfig((prev) => ({
                        ...prev,
                        letterhead: parseInt(e.target.value),
                      }))
                    }
                  >
                    <option value={1}>Style 1</option>
                    <option value={2}>Style 2</option>
                    <option value={3}>Style 3</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group>
                  <Form.Label>Watermark Opacity</Form.Label>
                  <Form.Range
                    value={config.watermarkOpacity}
                    onChange={(e) =>
                      setConfig((prev) => ({
                        ...prev,
                        watermarkOpacity: parseFloat(e.target.value),
                      }))
                    }
                    min={0}
                    max={1}
                    step={0.1}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="1">
          <Accordion.Header>
            <Type className="me-2" />
            Typography & Colors
          </Accordion.Header>
          <Accordion.Body>
            <Row className="g-3">
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Label Font Size</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="number"
                      value={config.fieldsStyles.labelFontSize}
                      onChange={(e) =>
                        handleStyleChange(
                          parseInt(e.target.value),
                          "labelFontSize"
                        )
                      }
                    />
                    <InputGroup.Text>px</InputGroup.Text>
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Value Font Size</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="number"
                      value={config.fieldsStyles.valueFontSize}
                      onChange={(e) =>
                        handleStyleChange(
                          parseInt(e.target.value),
                          "valueFontSize"
                        )
                      }
                    />
                    <InputGroup.Text>px</InputGroup.Text>
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col md={6}>
                <ColorPickerButton
                  color={config.fieldsStyles.labelColor}
                  onClick={() =>
                    setActiveColorPicker("fieldsStyles.labelColor")
                  }
                  label="Label Color"
                />
              </Col>
              <Col md={6}>
                <ColorPickerButton
                  color={config.fieldsStyles.valueColor}
                  onClick={() =>
                    setActiveColorPicker("fieldsStyles.valueColor")
                  }
                  label="Value Color"
                />
              </Col>
            </Row>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="2">
          <Accordion.Header>
            <FileText className="me-2" />
            Border Settings
          </Accordion.Header>
          <Accordion.Body>
            <Row className="g-3">
              <Col md={12}>
                <Form.Check
                  type="switch"
                  id="border-toggle"
                  label="Show Border"
                  checked={config.border.show}
                  onChange={() =>
                    setConfig((prev) => ({
                      ...prev,
                      border: { ...prev.border, show: !prev.border.show },
                    }))
                  }
                />
              </Col>
              <Col md={6}>
                <ColorPickerButton
                  color={config.border.outerBorderColor}
                  onClick={() =>
                    setActiveColorPicker("border.outerBorderColor")
                  }
                  label="Outer Border Color"
                />
              </Col>
              <Col md={6}>
                <ColorPickerButton
                  color={config.border.innerborderColor}
                  onClick={() =>
                    setActiveColorPicker("border.innerborderColor")
                  }
                  label="Inner Border Color"
                />
              </Col>
            </Row>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="3">
          <Accordion.Header>
            <Users className="me-2" />
            Field Visibility
          </Accordion.Header>
          <Accordion.Body>
            <Row className="g-3">
              {Object.entries(config.fields).map(([field, value]) => (
                <Col md={6} key={field}>
                  <Form.Check
                    type="switch"
                    id={`field-${field}`}
                    label={field
                      .replace(/([A-Z])/g, " $1")
                      .trim()
                      .split(" ")
                      .map(
                        (word) => word.charAt(0).toUpperCase() + word.slice(1)
                      )
                      .join(" ")}
                    checked={value}
                    onChange={() => handleFieldToggle(field)}
                  />
                </Col>
              ))}
            </Row>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      {activeColorPicker && (
        <div className="color-picker-overlay">
          <div
            className="color-picker-backdrop"
            onClick={() => setActiveColorPicker(null)}
          />
          <div className="color-picker-popover">
            <SketchPicker
              color={
                activeColorPicker.includes(".")
                  ? config[activeColorPicker.split(".")[0]][
                      activeColorPicker.split(".")[1]
                    ]
                  : config[activeColorPicker]
              }
              onChange={(color) => handleColorChange(color, activeColorPicker)}
            />
          </div>
        </div>
      )}

      <style>
        {`
          .color-picker-overlay {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1000;
          }
          .color-picker-backdrop {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
          }
          .color-picker-popover {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 1001;
          }
          .color-preview:hover {
            transform: scale(1.1);
          }
        `}
      </style>

      <div className="mt-4 d-flex justify-content-end">
        <Button
          variant="primary"
          onClick={() =>
            baptismConfig?.configId
              ? updateBaptismConfigs()
              : saveBaptismConfigs()
          }
          disabled={loading}
        >
          {loading ? (
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="me-2"
              />
              Saving...
            </>
          ) : (
            "Save Configurations"
          )}
        </Button>
      </div>
    </div>
  );
};

export default withAPIRequest(BaptismConfigs);

import React, { useState } from "react";
import Header from "../../layouts/Header";
import { Button, Card, Carousel, Col, Nav, Row } from "react-bootstrap";
import ParishDashboard from "./ParishDashboard";
import ParishUsers from "./ParishUsers";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ConfigurationsModal from "../../modals/settings/ConfigurationsModal";

const ParishDetails = () => {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  const navigate = useNavigate();
  const location = useLocation();

  const [selectedTab, setSelectedTab] = useState(1);
  const [editConfigModal, setEditConfigModal] = useState(false);

  console.log("location,", location.state);

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />

      <div className="main main-app p-3 p-lg-4 p-xxl-5">
        <div className="d-flex mb-4 justify-content-between">
          <div className="d-flex">
            <Button onClick={() => navigate(-1)} variant="outline">
              <i className="ri-arrow-left-line"></i>
            </Button>
            <div className="ms-3">
              <h5 id="section5" className="main-subtitle m-0">
                {location?.state?.churchName}
              </h5>
              <p className="m-0"> {location?.state?.churchAddress}</p>
            </div>
          </div>

          <Button
            onClick={() => {
              setEditConfigModal(true);
            }}
            variant="primary"
            className="d-flex justify-content-center align-items-center"
          >
            <i className="ri-settings-line fs-24"></i>
            <span className="ms-2">Configurations</span>
          </Button>
        </div>

        <Card className="card-example m-0">
          <Card.Body>
            <Nav variant="pills" fill>
              <Nav.Item>
                <Nav.Link
                  onClick={() => setSelectedTab(1)}
                  active={selectedTab === 1}
                >
                  Dashboard
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  onClick={() => setSelectedTab(2)}
                  active={selectedTab === 2}
                >
                  Users
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  onClick={() => setSelectedTab(3)}
                  active={selectedTab === 3}
                  disabled
                >
                  Financials
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Card.Body>
          {/* <Card.Footer>
            <pre>
              <code className="language-html">{`<Nav variant="pills" fill>...</Nav>`}</code>
            </pre>
          </Card.Footer> */}
        </Card>
        <hr />

        {selectedTab === 1 && (
          <ParishDashboard churchId={location?.state?.churchId} />
        )}
        {selectedTab === 2 && (
          <ParishUsers
            selectedTab={selectedTab}
            churchId={location?.state?.churchId}
          />
        )}

        {/* <Footer /> */}
      </div>

      <ConfigurationsModal
        show={editConfigModal}
        setShow={setEditConfigModal}
        churchId={location?.state?.churchId}
      />
    </React.Fragment>
  );
};

export default ParishDetails;

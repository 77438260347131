import { Button, Spinner, Modal, Row } from "react-bootstrap";
import React, { useState } from "react";
import Lottie from "lottie-react";
import loadingAnimation from "../../assets/lottie/loading_animation_delhi_theme.json";
import { BlobProvider } from "@react-pdf/renderer";
import BaptismCertificatePdf from "../../components/sacrament-certificates/BaptismCertificatePdf";
import HolyCommunionCertificatePdf from "../../components/sacrament-certificates/HolyCommunionCertificatePdf";
const HolyCommunionLinkCertificateModal = ({
  show,
  setShow,
  churchDetails,
  currentHolyCommunion,
}) => {
  const [loading, setLoading] = useState(false);

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      size="md"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold">
          Holy Communion Certificate Link
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ alignSelf: "center" }}>
        {loading ? (
          <div
            style={{ height: 400 }}
            className="justify-content-center align-items-center d-flex"
          >
            <Lottie
              style={{ width: 200, height: 200 }}
              animationData={loadingAnimation}
              loop={true}
            />
          </div>
        ) : (
          <BlobProvider
            key={currentHolyCommunion?.id}
            document={
              <HolyCommunionCertificatePdf
                details={currentHolyCommunion}
                churchDetails={churchDetails}
              />
            }
          >
            {({ blob, url, loading, error }) => {
              // Handle loading state
              if (loading) {
                return (
                  <Button
                    style={{ width: 120 }}
                    // variant="primary"
                    disabled
                  >
                    <Spinner animation="border" variant="light" />
                  </Button>
                );
              }
              // Handle error state
              if (error) {
                return (
                  <Button className="rounded-pill" variant="danger" disabled>
                    Error
                  </Button>
                );
              }
              // Function to open PDF in a new tab
              const openPdf = () => {
                const pdfWindow = window.open();
                pdfWindow.location.href = url;
              };
              return (
                <Button
                  variant="primary"
                  style={{ width: 120 }}
                  // className="rounded-pill"
                  onClick={openPdf}
                >
                  <i className="ri-file-text-line"></i> Download Pdf
                </Button>
              );
            }}
          </BlobProvider>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default HolyCommunionLinkCertificateModal;

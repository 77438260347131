import { Button } from "react-bootstrap";
import React from "react";
import { API, AXIOS_METHOD_TYPES } from "../../constants/api";
import * as XLSX from "xlsx";
import { withAPIRequest } from "../../HOC/withAPIRequest";

const SubZoneExcelReport = ({ commonAPIRequest, subZoneId, subZoneName }) => {
  const header = [
    "S.No",
    "Zone",
    "Church Family ID",
    "Status",
    "Name",
    "Gender",
    "Phone",
    "Whatsapp Number",
    "Email",
    "Address",
    "Remark",
  ];

  const getAllFamilyDetailsApi = () => {
    let serviceParams = {
      api: `${API.GET_ALL_FAMILY}?pgNum=1&pgSize=1000&subZoneId=${subZoneId}`,
      method: AXIOS_METHOD_TYPES.GET,
      data: null,
    };

    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        try {
          const data = result.payload?.headDetails.map((detail, i) => {
            return [
              i + 1,
              detail?.zoneName,
              detail?.churchFamilyId,
              detail?.active ? "Active" : "Inactive",
              `${detail?.firstName} ${detail?.middleName} ${detail?.lastName}`,
              detail?.gender,
              detail?.phNumber,
              detail?.whatsAppNumber,
              detail?.emailAddress,
              detail?.address?.addressLine1,
              detail?.remark,
            ];
          });

          // Prepare worksheet data
          const wsData = [header, ...data];
          const ws = XLSX.utils.aoa_to_sheet(wsData);

          // Create workbook and append the worksheet
          const wb = XLSX.utils.book_new();
          const sheetName = `families in ${subZoneName}`.substring(0, 31);
          XLSX.utils.book_append_sheet(wb, ws, sheetName);

          // Write workbook to file
          XLSX.writeFile(wb, `Families in Area ${subZoneName}.xlsx`);
        } catch (error) {
          console.error("Error generating Excel report:", error);
        }
      }
    });
  };

  function handleDownloadExcel() {
    getAllFamilyDetailsApi();
  }

  return (
    <div>
      <Button className="ms-3" onClick={handleDownloadExcel}>
        <i className="ri-download-2-fill"></i> Download SubZone Report
      </Button>
    </div>
  );
};

export default withAPIRequest(SubZoneExcelReport);

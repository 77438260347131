import React from "react";
import { withAPIRequest } from "../../HOC/withAPIRequest";
import { Button } from "react-bootstrap";
import * as XLSX from "xlsx";
import { API, AXIOS_METHOD_TYPES } from "../../constants/api";

const ExpenseExcelReport = ({
  totalCount,
  currentMonth,
  commonAPIRequest,
  currentYear,
}) => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const header = [
    "S.No",
    "Amount",
    "Expense Made On",
    "Mode",
    "Purpose",
    "Added By",
  ];

  const getAllExpensesApi = () => {
    let serviceParams = {
      api: `${
        API.EXPENSE_SUMMARY
      }?pgNum=1&pgSize=${1000}&month=${currentMonth}&year=${currentYear}`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    // setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        // setLoading(false);
        try {
          const data = result.payload?.expenseDetailList.map((expense, i) => {
            return {
              sno: i + 1,
              amount: expense.amount,
              expenseMadeOn: new Date(expense.expenseDate).toLocaleDateString(
                "en-IN"
              ),
              mode: expense.paymentMode,
              purpose: expense.description,
              addedBy: expense.createdBy,
            };
          });

          const wsData = [
            header,
            ...data.map((row) => [
              row.sno,
              row.amount,
              row.expenseMadeOn,
              row.mode,
              row.purpose,
              row.addedBy,
            ]),
          ];

          const ws = XLSX.utils.aoa_to_sheet(wsData);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "Expenses");
          XLSX.writeFile(
            wb,
            `Expenses_${months[currentMonth - 1]}_${currentYear}.xlsx`
          );
        } catch (error) {
          console.log("error", error);
        }
      } else {
        // setLoading(false);
      }
    });
  };

  function handleDownloadExcel() {
    getAllExpensesApi();
  }

  return (
    <div>
      <Button className="ms-3" onClick={handleDownloadExcel}>
        <i className="ri-download-2-fill"></i>
      </Button>
    </div>
  );
};

export default withAPIRequest(ExpenseExcelReport);

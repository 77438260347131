import React from "react";
import { Button } from "react-bootstrap";
import { API, AXIOS_METHOD_TYPES } from "../../constants/api";
import { withAPIRequest } from "../../HOC/withAPIRequest";
import * as XLSX from "xlsx";

const ZoneExcelReport = ({
  commonAPIRequest,
  totalCount,
  zoneId,
  zoneName,
}) => {
  const header = [
    "S.No",
    "Zone",
    "Church Family ID",
    "Status",
    "Name",
    "Gender",
    "Phone",
    "Whatsapp Number",
    "Email",
    "Address",
    "Remark",
  ];

  const getAllFamilyDetailsApi = () => {
    let serviceParams = {
      api: `${API.GET_ALL_FAMILY}?pgNum=1&pgSize=${totalCount}&zoneId=${zoneId}`,
      method: AXIOS_METHOD_TYPES.GET,
      data: null,
    };

    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        try {
          const data = result.payload?.headDetails.map((detail, i) => ({
            sno: i + 1,
            zone: detail?.zoneName,
            familyId: detail?.churchFamilyId,
            status: detail?.active ? "Active" : "Inactive",
            name: `${detail?.firstName} ${detail?.middleName} ${detail?.lastName}`,
            gender: detail?.gender,
            phone: detail?.phNumber,
            whatsAppNumber: detail?.whatsAppNumber,
            email: detail?.emailAddress,
            address: detail?.address?.addressLine1,
            remark: detail?.remark,
          }));

          // Combine headers and data for Excel
          const wsData = [
            header,
            ...data.map((row) => [
              row.sno,
              row.zone,
              row.familyId,
              row.status,
              row.name,
              row.gender,
              row.phone,
              row.whatsAppNumber,
              row.email,
              row.address,
              row.remark,
            ]),
          ];

          // Generate worksheet and workbook
          const ws = XLSX.utils.aoa_to_sheet(wsData);
          const wb = XLSX.utils.book_new();
          const sheetName = `families in ${zoneName}`.substring(0, 31);
          XLSX.utils.book_append_sheet(wb, ws, sheetName);

          // Save workbook
          XLSX.writeFile(
            wb,
            `Families in ${zoneName.split(".").join(" ")}.xlsx`
          );
        } catch (error) {
          console.error("Error generating Excel file:", error);
        }
      }
    });
  };

  function handleDownloadExcel() {
    getAllFamilyDetailsApi();
  }

  return (
    <div>
      <Button className="ms-3" onClick={handleDownloadExcel}>
        <i className="ri-download-2-fill"></i>
      </Button>
    </div>
  );
};

export default withAPIRequest(ZoneExcelReport);

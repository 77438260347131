import React, { useState } from "react";
import { Button, Card, Col, Row, Badge, Spinner } from "react-bootstrap";
import Lottie from "lottie-react";
import loadingAnimation from "../../assets/lottie/loading_animation_delhi_theme.json";
import noSearchAnimation from "../../assets/lottie/../../assets/lottie/not_found_lottie_delhi_diocese_theme.json";
import ChooseMarriageTypeModal from "../../modals/marriage/ChooseMarriageTypeModal";
import AddExternalMarriageDetailsModal from "../../modals/marriage/AddExternalMarriageDetailsModal";
import PreMarriageDetailsModal from "../../modals/marriage/PreMarriageDetailsModal";
import ImportMemberModal from "../../modals/marriage/ImportMemberModal";
import AddMarriageDetailsModal from "../../modals/marriage/AddMarriageDetailsModal";
import { API, AXIOS_METHOD_TYPES } from "../../constants/api";
import { withAPIRequest } from "../../HOC/withAPIRequest";
import ImportMemberCreateNewFamilyModal from "../../modals/marriage/ImportMemberCreateNewFamilyModal";
import ImportMemberBasicFamilyDetailsModal from "../../modals/marriage/ImportMemberBasicFamilyDetailsModal";
import MarriageInfoOffset from "../../offset-canvas/marriage/MarriageInfoOffset";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import MarriageLinkCertificateModal from "../../modals/marriage/MarriageLinkCertificateModal";
import GenerateMarriageCertificateModal from "../../modals/marriage/GenerateMarriageCertificateModal";

function getAge(dateString) {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

const MarriageDetails = ({
  basicDetails,
  memberId,
  getFamilyMemberDetails,
  holyConfirmationDetails,
  holyOrderDetails,
  deathDetails,
  familyDetails,
  marriageDetails,
  commonAPIRequest,
  baptismDetails,
}) => {
  const [chooseMarriageModalVisible, setChooseMarriageModalVisible] =
    useState(false);

  const [addExternalMarriageModalVisible, setAddExternalMarriageModalVisible] =
    useState(false);

  const [preMarriageModalVisible, setPreMarriageModalVisible] = useState(false);

  const [importMemberModalVisible, setImportMemberModalVisible] =
    useState(false);
  const [addMarriageDetailsModalVisible, setAddMarriageDetailsModalVisible] =
    useState(false);
  const [createFamilyModalVisible, setCreateFamilyModalVisible] =
    useState(false);
  const [newFamilyDetailsModalVisible, setNewFamilyDetailsModalVisible] =
    useState(false);
  const [marriageOffsetModalVisible, setMarriageOffsetModalVisible] =
    useState(false);

  const [certPdf, setCertPdf] = useState();
  const [loading, setLoading] = useState(false);
  const [currentMarriage, setCurrentMarriage] = useState();
  const [marriageLinkModal, setMarriageLinkModal] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [generateMarriageModal, setGenerateMarriageModal] = useState(false);
  const { churchDetails } = useSelector((state) => state?.church);

  // call generate pdf certificate api for marriage start

  const callGetMarriageCertificateApi = () => {
    let serviceParams = {
      api: `${API.GENERATE_MARRIAGE_CERTIFICATE}?marriageId=${marriageDetails?.marriageId}`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          setCertPdf(result?.payload);
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  // call generate pdf certificate api for marriage end

  // call generate pdf certificate api for baptism end

  const callGetMarriageDetailsForLink = (id) => {
    let serviceParams = {
      api: `${API.MARRIAGE_DETAILS}?marriageId=${id}`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading2(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading2(false);
        try {
          // console.log("result", result.payload);
          setCurrentMarriage(result?.payload);
          setMarriageLinkModal(true);
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading2(false);
      }
    });
  };

  const callGetMarriageDetailsForPreview = (id) => {
    let serviceParams = {
      api: `${API.MARRIAGE_DETAILS}?marriageId=${id}`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          // console.log("result", result.payload);
          setCurrentMarriage(result?.payload);
          setGenerateMarriageModal(true);
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  return (
    <div>
      {marriageDetails ? (
        <Card className="card-post mt-4 ">
          <Card.Header>
            <h3 className="fw-bold text-primary">Marriage Information</h3>
            {!marriageDetails?.extMarriage && (
              <Button onClick={() => setMarriageOffsetModalVisible(true)}>
                <i className="ri-information-line"></i>
              </Button>
            )}
          </Card.Header>
          <Card.Body>
            <Row>
              <Col>
                <Row>
                  <Col sm={4}>
                    <div className="my-2">
                      <p className="mb-0">
                        <strong>Marriage Done?</strong>
                      </p>
                      <p className="mb-0">Yes</p>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="my-2">
                      <p className="mb-0">
                        <strong>Date Of Marriage</strong>
                      </p>
                      <div className="d-flex align-items-center justify-content-start">
                        <p className="mb-0">
                          {" "}
                          {new Date(
                            marriageDetails?.marriageDate
                          ).toLocaleDateString("en-GB", {
                            day: "numeric",
                            month: "long",
                            year: "numeric",
                          })}
                        </p>
                        {!marriageDetails?.marriageDateAccurate && (
                          <Badge
                            style={{ padding: 5 }}
                            className="ms-2 fw-bold"
                            bg="warning"
                          >
                            Uncertain
                          </Badge>
                        )}
                      </div>
                    </div>
                  </Col>
                  {!marriageDetails?.extMarriage && (
                    <Col sm={4}>
                      <div className="my-2">
                        <p className="mb-0">
                          <strong>Marriage Certificate ID</strong>
                        </p>
                        <p className="mb-0">{marriageDetails?.referenceNum}</p>
                      </div>
                    </Col>
                  )}
                  <Col sm={4}>
                    <div className="my-2">
                      <p className="mb-0">
                        <strong>External Marriage</strong>
                      </p>
                      <p className="mb-0">
                        {marriageDetails?.extMarriage
                          ? marriageDetails?.extMarriageChurch ||
                            marriageDetails?.extMarriageCity
                            ? "Yes"
                            : "Not Sure"
                          : "No"}
                      </p>
                    </div>
                  </Col>
                  {marriageDetails?.extMarriageChurch && (
                    <Col sm={4}>
                      <div className="my-2">
                        <p className="mb-0">
                          <strong>External Marriage Church</strong>
                        </p>
                        <p className="mb-0">
                          {marriageDetails?.extMarriageChurch}
                        </p>
                      </div>
                    </Col>
                  )}
                  {marriageDetails?.extMarriageCity && (
                    <Col sm={4}>
                      <div className="my-2">
                        <p className="mb-0">
                          <strong>External Marriage City</strong>
                        </p>
                        <p className="mb-0">
                          {marriageDetails?.extMarriageCity}
                        </p>
                      </div>
                    </Col>
                  )}
                  <Col sm={4}>
                    <div className="my-2">
                      <p className="mb-0">
                        <strong>Spouse Name</strong>
                      </p>
                      {basicDetails?.gender === "Male" ? (
                        <p className="mb-0">
                          {marriageDetails?.brideMiddleName
                            ? `${marriageDetails?.brideFirstName} ${marriageDetails?.brideMiddleName} ${marriageDetails?.brideLastName}`
                            : `${marriageDetails?.brideFirstName} ${marriageDetails?.brideLastName}`}
                        </p>
                      ) : (
                        <p className="mb-0">
                          {marriageDetails?.groomMiddleName
                            ? `${marriageDetails?.groomFirstName} ${marriageDetails?.groomMiddleName} ${marriageDetails?.groomLastName}`
                            : `${marriageDetails?.groomFirstName} ${marriageDetails?.groomLastName}`}
                        </p>
                      )}
                    </div>
                  </Col>
                </Row>
                {!marriageDetails?.extMarriage && (
                  <div>
                    <hr className="hr" />
                    <div className="text-center m-4">
                      <Card.Title className="text-dark">
                        Generate Marriage Certificate for{" "}
                        {basicDetails?.firstName} {basicDetails?.middleName}{" "}
                        {basicDetails?.lastName}
                      </Card.Title>
                      <Card.Text>
                        Please click on Generate for generating marriage
                        certificate copy for{" "}
                        <span className="fw-bold text-primary">
                          {" "}
                          {basicDetails?.firstName} {basicDetails?.middleName}{" "}
                          {basicDetails?.lastName}
                        </span>
                      </Card.Text>
                      {/* {loading ? (
                        <div className="justify-content-center align-items-center d-flex">
                          <Lottie
                            style={{ width: 100, height: 100 }}
                            animationData={loadingAnimation}
                            loop={true}
                          />
                        </div>
                      ) : certPdf?.certName ? (
                        <Button
                          onClick={() => {
                            let pdfWindow = window.open("");
                            pdfWindow.document.write(
                              "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
                                encodeURI(certPdf.base64) +
                                "'></iframe>"
                            );
                          }}
                          variant="primary"
                        >
                          Print
                        </Button>
                      ) : (
                        <Button
                          onClick={callGetMarriageCertificateApi}
                          variant="primary"
                        >
                          Generate
                        </Button>
                      )} */}

                      <div>
                        <Button
                          disabled={marriageDetails?.extBaptism}
                          variant="primary"
                          onClick={() => {
                            callGetMarriageDetailsForLink(
                              marriageDetails?.marriageId
                            );
                          }}
                        >
                          {loading2 ? (
                            <span className="d-flex align-items-center">
                              <Spinner
                                className="me-2"
                                animation="border"
                                variant="light"
                                size="sm"
                              />
                              Generating...
                            </span>
                          ) : (
                            <div className="d-flex align-items-center justify-content-center">
                              <i className="ri-download-2-fill me-2"></i>
                              Download Pdf
                            </div>
                          )}
                        </Button>

                        {/* <Button
                          variant="primary"
                          className="ms-2"
                          onClick={() => {
                            callGetMarriageDetailsForPreview(
                              marriageDetails?.marriageId
                            );
                          }}
                        >
                          {loading ? (
                            <span className="d-flex align-items-center">
                              <Spinner
                                className="me-2"
                                animation="border"
                                variant="light"
                              />
                              Generating...
                            </span>
                          ) : (
                            <i className="ri-eye-line"></i>
                          )}
                        </Button> */}
                      </div>
                    </div>
                  </div>
                )}
              </Col>
            </Row>
          </Card.Body>
          {
            <Card.Footer className="bg-light text-primary text-end">
              Last Updated by{" "}
              <span className="fw-bold">{marriageDetails?.updatedBy}</span> on
              <span className="fw-bold">
                {" "}
                {new Date(marriageDetails?.updateTmStmp).toLocaleDateString(
                  "en-GB",
                  {
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                  }
                )}
              </span>
            </Card.Footer>
          }
        </Card>
      ) : (
        <Card.Body className="text-center m-4">
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="text-center"
            >
              <Lottie
                style={{ width: 150, height: 150 }}
                animationData={noSearchAnimation}
                loop={true}
              />
            </div>
          </div>
          <Card.Title className="text-dark">
            No Marriage Details Found
          </Card.Title>
          <Card.Text>
            Please add Marriage details for{" "}
            <span className="fw-bold text-primary">
              {basicDetails?.firstName} {basicDetails?.middleName}{" "}
              {basicDetails?.lastName}
            </span>{" "}
            and generate certificate.
            <br />
            <span className="text-danger fw-bold">
              NOTE: *Holy Confirmation is required for adding Marriage details.
            </span>
          </Card.Text>
          <Button
            onClick={() => {
              if (+getAge(basicDetails?.dateOfBirth) > 18) {
                if (
                  basicDetails?.familyHead &&
                  basicDetails?.gender === "Male"
                ) {
                  setPreMarriageModalVisible(true);
                } else if (
                  basicDetails?.relationToHead === "HUSBAND" &&
                  basicDetails?.gender === "Male"
                ) {
                  setPreMarriageModalVisible(true);
                } else if (
                  basicDetails?.gender === "Male" &&
                  !basicDetails?.familyHead
                ) {
                  setCreateFamilyModalVisible(true);
                } else {
                  // setChooseMarriageModalVisible(true);
                  Swal.fire(
                    "Info",
                    `Please Add Marriage Details from Groom's Profile.`,
                    "info"
                  );
                }
              } else {
                Swal.fire(
                  "Warning",
                  `Member age is below 18 years.`,
                  "warning"
                );
              }
            }}
            disabled={
              holyConfirmationDetails?.confirmationId &&
              !deathDetails &&
              !holyOrderDetails?.holyOrderId
                ? false
                : true
            }
            variant="primary"
          >
            Add
          </Button>
        </Card.Body>
      )}

      <PreMarriageDetailsModal
        show={preMarriageModalVisible}
        setShow={setPreMarriageModalVisible}
        basicDetails={basicDetails}
        setImportMemberModalVisible={setImportMemberModalVisible}
        setChooseMarriageModalVisible={setChooseMarriageModalVisible}
      />

      <ImportMemberModal
        show={importMemberModalVisible}
        setShow={setImportMemberModalVisible}
        basicDetails={basicDetails}
        familyDetails={familyDetails}
        getFamilyMemberDetails={getFamilyMemberDetails}
      />

      <ChooseMarriageTypeModal
        show={chooseMarriageModalVisible}
        setShow={setChooseMarriageModalVisible}
        basicDetails={basicDetails}
        setAddExternalMarriageModalVisible={setAddExternalMarriageModalVisible}
        setAddMarriageDetailsModalVisible={setAddMarriageDetailsModalVisible}
      />
      <AddExternalMarriageDetailsModal
        show={addExternalMarriageModalVisible}
        setShow={setAddExternalMarriageModalVisible}
        basicDetails={basicDetails}
        holyConfirmationDetails={holyConfirmationDetails}
        memberId={memberId}
        getFamilyMemberDetails={getFamilyMemberDetails}
        familyDetails={familyDetails}
        baptismDetails={baptismDetails}
      />
      <AddMarriageDetailsModal
        show={addMarriageDetailsModalVisible}
        setShow={setAddMarriageDetailsModalVisible}
        basicDetails={basicDetails}
        holyConfirmationDetails={holyConfirmationDetails}
        memberId={memberId}
        getFamilyMemberDetails={getFamilyMemberDetails}
        familyDetails={familyDetails}
        baptismDetails={baptismDetails}
      />

      <ImportMemberCreateNewFamilyModal
        show={createFamilyModalVisible}
        setShow={setCreateFamilyModalVisible}
        basicDetails={basicDetails}
        setPreMarriageModalVisible={setPreMarriageModalVisible}
        setNewFamilyDetailsModalVisible={setNewFamilyDetailsModalVisible}
      />
      <ImportMemberBasicFamilyDetailsModal
        show={newFamilyDetailsModalVisible}
        setShow={setNewFamilyDetailsModalVisible}
        basicDetails={basicDetails}
        memberId={memberId}
        familyDetails={familyDetails}
      />
      <MarriageInfoOffset
        show={marriageOffsetModalVisible}
        setShow={setMarriageOffsetModalVisible}
        marriageId={marriageDetails?.marriageId}
        holyConfirmationDetails={holyConfirmationDetails}
        basicDetails={basicDetails}
      />

      <MarriageLinkCertificateModal
        show={marriageLinkModal}
        setShow={setMarriageLinkModal}
        currentMarriage={currentMarriage}
        marriageId={marriageDetails?.marriageId}
        churchDetails={churchDetails}
      />
      <GenerateMarriageCertificateModal
        setShow={setGenerateMarriageModal}
        show={generateMarriageModal}
        marriageId={marriageDetails?.marriageId}
        churchDetails={churchDetails}
        currentMarriage={currentMarriage}
      />
    </div>
  );
};

export default withAPIRequest(MarriageDetails);

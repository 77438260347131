import React, { useContext, useEffect, useState } from "react";
import Header from "../layouts/Header";
import { withAPIRequest } from "../HOC/withAPIRequest";
import { Button, Card, Col, Row } from "react-bootstrap";
import { AuthContext } from "react-oauth2-code-pkce";
import Swal from "sweetalert2";
import quoteImage from "../assets/img/quote-of the-day.png";

// -------------------------- DATA ------------------------------

import { useDispatch, useSelector } from "react-redux";
import { API, AXIOS_METHOD_TYPES } from "../constants/api";
import { ChurchAction } from "../store/slices/ChurchSlice";
import BirthdaySection from "./analytics-components/BirthdaySection";
import FinanceSection from "./analytics-components/FinanceSection";
import Lottie from "lottie-react";
import loadingAnimation from "../assets/lottie/loading_animation_delhi_theme.json";
import { AnalyticsAction } from "../store/slices/AnalyticsSlice";
import AnniversarySection from "./analytics-components/AnniversarySection";
import ReactApexChart from "react-apexcharts";
import {
  optionOne,
  optionThree,
  optionTwo,
  seriesOne,
  seriesThree,
  seriesTwo,
} from "../data/DashboardData2";
import NotAuthenticated from "../pages/NotAuthenticated";

// -------------------------- DATA ------------------------------

function Analytics({ commonAPIRequest }) {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  const { token, tokenData } = useContext(AuthContext);

  console.log("token-data", tokenData);

  const timestampSecondsNow = Math.floor(Date.now() / 1000);
  console.log(timestampSecondsNow); // Example output: 1716496364

  const dispatch = useDispatch();
  const { present } = useSelector((state) => state.church);
  const { analyticsPresent, analytics } = useSelector(
    (state) => state.analytics
  );
  const [loading, setLoading] = useState(false);

  const [dashboardData, setDashboardData] = useState();

  const dashboardPermission =
    tokenData?.permissions?.includes("READ_DASHBOARD");

  const callGetChurchDetailsApi = () => {
    let serviceParams = {
      api: API.GET_CHURCH,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          dispatch(ChurchAction.setChurchDetails(result?.payload));
          console.log("result?.payload", result?.payload);
          localStorage.setItem("churchName", result?.payload?.churchName);
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  const callGetUserDetailsApi = () => {
    let serviceParams = {
      api: API.GET_USER_INFO,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          dispatch(ChurchAction.setChurchUserDetails(result?.payload));
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  const callGetDashboardData = () => {
    let serviceParams = {
      api: API.DASHBOARD_DATA,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          setDashboardData(result?.payload);
          dispatch(AnalyticsAction.setAnalytics(result?.payload));
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    if (!present) {
      callGetChurchDetailsApi();
      callGetUserDetailsApi();
    }
  }, [present]);

  useEffect(() => {
    if (!analyticsPresent && dashboardPermission) {
      callGetDashboardData();
    }
  }, [analyticsPresent]);

  useEffect(() => {
    if (tokenData?.passExpOn - timestampSecondsNow < 604800) {
      Swal.fire({
        title: "Update Password!",
        text: `Your password is expiring on ${new Date(
          tokenData?.passExpOn * 1000
        ).toLocaleDateString("en-GB")}`,
        imageUrl: require("../assets/img/pasword-expiry-illustration.png"),
        imageWidth: 300,
        imageHeight: 300,
        imageAlt: "Custom image",
      });
      // Swal.fire(
      //   "Update Password",
      //   `Your password is expiring on ${new Date(
      //     tokenData?.passExpOn * 1000
      //   ).toLocaleDateString("en-GB")}`,
      //   "warning"
      // );
    }
  }, []);

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4 ">
        {loading ? (
          <div
            className=" d-flex align-items-center justify-content-center "
            style={{ height: "90vh" }}
          >
            <Lottie
              style={{ width: 160, height: 160 }}
              animationData={loadingAnimation}
              loop={true}
            />
            <span style={{ marginLeft: -40 }}>Loading Dashboard...</span>
          </div>
        ) : !dashboardPermission ? (
          <NotAuthenticated />
        ) : (
          <Row className="g-3">
            <div className="d-none d-sm-inline">
              <Col
                style={{ borderRadius: 10, backgroundColor: "#e7e7e7" }}
                className="d-flex my-2 p-2 allign-items-center justify-content-between"
              >
                <marquee
                  className="fw-bold mt-2 me-3"
                  behavior="scroll"
                  direction="left"
                  scrollamount="5"
                >
                  For God so loved the world that he gave his one and only Son,
                  that whoever believes in him shall not perish but have eternal
                  life. <span className="fw-light">- John [3:16]</span>
                </marquee>

                <Button onClick={() => callGetDashboardData()} variant="light">
                  <i className="ri-refresh-line"></i>
                </Button>
              </Col>
            </div>

            <div className="d-sm-none pt-0  ">
              <Col>
                <img
                  className="mb-3"
                  style={{ borderRadius: 10, width: "100%" }}
                  src={quoteImage}
                  alt="quoteimage"
                />
                <div
                  style={{
                    position: "absolute",
                    top: 120,
                    // backgroundColor: "red",
                    width: "50%",
                  }}
                  className="text-white ms-3"
                >
                  For God so loved the world that he gave his one and only Son,
                  that whoever believes in him shall not perish but have eternal
                  life.
                  <div className="d-flex justify-content-end">
                    <span className="fw-light">- John [3:16]</span>
                  </div>
                </div>
              </Col>
            </div>

            <FinanceSection data={analytics?.financialsInfo} />
            {/*  */}

            {[
              {
                label: "Baptism's Done",
                icon: "ri-showers-line",
                valuemonth: analytics?.sacramentsInfo?.baptismsThisMonth,
                valueYear: analytics?.sacramentsInfo?.baptismsThisYear,
                percent: "0.7",
                status: "down",
              },
              {
                label: "Holy Communion's Done",
                icon: "ri-briefcase-4-line",
                valuemonth: analytics?.sacramentsInfo?.holyCommunionsThismonth,
                valueYear: analytics?.sacramentsInfo?.holyCommunionsThisYear,
                percent: "2.1",
                status: "up",
              },
              {
                label: "Holy Confirmation's Done",
                icon: "ri-star-line",
                valuemonth: analytics?.sacramentsInfo?.confirmationsThisMonth,
                valueYear: analytics?.sacramentsInfo?.confirmationsThisYear,
                percent: "0.3",
                status: "down",
              },
              {
                label: "Marriage's Done",
                icon: "ri-heart-line",
                valuemonth: analytics?.sacramentsInfo?.marriagesThisMonth,
                valueYear: analytics?.sacramentsInfo?.marriagesThisYear,
                percent: "1.2",
                status: "up",
              },
            ].map((card, index) => (
              <Col xs="6" xl="3" key={index}>
                <Card className="card-one">
                  <Card.Body>
                    <Card.Title
                      as="label"
                      style={{ width: "100%" }}
                      className="fs-sm fw-medium mb-1 text-primary fw-bold text-center"
                    >
                      {card.label}
                    </Card.Title>
                    <hr className="mt-0" />
                    <div className="d-flex justify-content-between">
                      <div>
                        <h3 className="card-value mb-1">
                          <i className={card.icon}></i> {card.valuemonth}
                        </h3>
                        <small>
                          in{" "}
                          {new Date().toLocaleDateString("en-IN", {
                            month: "long",
                            year: "numeric",
                          })}
                        </small>
                      </div>
                      {/*  */}
                      <div>
                        <h3 className="card-value mb-1">
                          <i className={card.icon}></i> {card.valueYear}
                        </h3>
                        <small>
                          in{" "}
                          {new Date().toLocaleDateString("en-IN", {
                            year: "numeric",
                          })}
                        </small>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))}

            {/*  */}

            <Col md="6" xl="3">
              <Card className="card-one">
                <Card.Body>
                  <Row>
                    <Col xs="7">
                      <h3 className="card-value mb-1">
                        {analytics?.memberStatistics?.familyCount}
                      </h3>
                      <label className="card-title fw-medium text-dark mb-1">
                        Total Families Registered
                      </label>
                      <span className="d-block text-muted fs-11 ff-secondary lh-4">
                        Total number of active families registered in the
                        parish.
                      </span>
                    </Col>
                    <Col xs="5">
                      <ReactApexChart
                        series={seriesOne}
                        options={optionOne}
                        type="bar"
                        height={70}
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>

            <Col md="6" xl="3">
              <Card className="card-one">
                <Card.Body>
                  <Row>
                    <Col xs="7">
                      <h3 className="card-value mb-1">
                        {analytics?.memberStatistics?.memberCount}
                      </h3>
                      <label className="card-title fw-medium text-dark mb-1">
                        Total Parishioner's Registered
                      </label>
                      <span className="d-block text-muted fs-11 ff-secondary lh-4">
                        Total number of active members registered in the parish.
                      </span>
                    </Col>
                    <Col xs="5">
                      <ReactApexChart
                        series={seriesTwo}
                        options={optionTwo}
                        type="bar"
                        height={70}
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col md="6" xl="3">
              <Card className="card-one">
                <Card.Body>
                  <Row>
                    <Col xs="7">
                      <h3 className="card-value mb-1">
                        {analytics?.memberStatistics?.youthCount}
                      </h3>
                      <label className="card-title fw-medium text-dark mb-1">
                        Total Youth Population
                      </label>
                      <span className="d-block text-muted fs-11 ff-secondary lh-4">
                        Total number of youth in the parish
                      </span>
                    </Col>
                    <Col xs="5">
                      <ReactApexChart
                        series={seriesThree}
                        options={optionThree}
                        type="bar"
                        height={70}
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>

            <Col md="6" xl="3">
              <Card className="card-one">
                <Card.Body>
                  <Row>
                    <Col xs="7">
                      <h3 className="card-value mb-1">
                        {analytics?.memberStatistics?.seniorCount}
                      </h3>
                      <label className="card-title fw-medium text-dark mb-1">
                        Total Senior Citizen's
                      </label>
                      <span className="d-block text-muted fs-11 ff-secondary lh-4">
                        Total number of senior citizen's in the parish.
                      </span>
                    </Col>
                    <Col xs="5">
                      <ReactApexChart
                        series={seriesThree}
                        options={optionThree}
                        type="bar"
                        height={70}
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>

            <Col lg={"6"}>
              <BirthdaySection
                birthdays={
                  analytics?.birthdayDetailList
                    ? analytics?.birthdayDetailList
                    : []
                }
              />
            </Col>

            <Col lg={"6"}>
              <AnniversarySection
                anniversarys={
                  analytics?.anniversaryDetailList
                    ? analytics?.anniversaryDetailList
                    : []
                }
              />
            </Col>
          </Row>
        )}
      </div>
    </React.Fragment>
  );
}

export default withAPIRequest(Analytics);

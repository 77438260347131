import React, { useEffect, useState } from "react";
import { Button, Modal, Row, Col, ListGroup } from "react-bootstrap";
import "./ConfigurationsModal.css"; // Importing the custom CSS
import BaptismConfigs from "./configs/BaptismConfigs";
import { API, AXIOS_METHOD_TYPES } from "../../constants/api";
import { withAPIRequest } from "../../HOC/withAPIRequest";

const ConfigurationsModal = ({ show, setShow, churchId, commonAPIRequest }) => {
  const [selectedSetting, setSelectedSetting] = useState(0); // Default to the first setting
  const [loading, setLoading] = useState(false);
  const [configs, setConfigs] = useState([]);

  // Example settings array to display as configurations
  const settings = [
    {
      name: "Baptism",
      content: "Baptism Certificate Config Settings",
      type: "baptism",
    },
    // { name: "Notifications", content: "Notification settings content here" },
    // { name: "Profile", content: "Profile settings content here" },
    // Add more settings as needed
  ];

  const callGetAllConfigurations = () => {
    let serviceParams = {
      api: API.GET_ALL_CONFIGURATIONS,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          setConfigs(result?.payload);
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  const renderSettingContent = (setting) => {
    switch (setting.type) {
      case "baptism":
        return (
          <BaptismConfigs
            baptismConfig={configs.find(
              (config) => config.name === "BAPTISM_CONFIGURATIONS"
            )}
            churchId={churchId}
            content={setting.content}
          />
        );
      default:
        return <div>{setting.content}</div>; // Default content rendering
    }
  };

  useEffect(() => {
    if (show) {
      callGetAllConfigurations();
    }
  }, [show]);

  console.log(
    "configs",
    configs.find((config) => config.name === "BAPTISM_CONFIGURATIONS")
  );

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      backdrop="static"
      keyboard={false}
      centered
      size="xl"
      className="config-modal" // Class for styling
    >
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold text-primary">
          Configuration Settings
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={4} className="config-sidebar">
            <ListGroup>
              {settings.map((setting, index) => (
                <ListGroup.Item
                  key={index}
                  action
                  onClick={() => setSelectedSetting(index)}
                  className={index === selectedSetting ? "active" : ""}
                >
                  {setting.name}
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Col>
          <Col md={8} className="config-content">
            {renderSettingContent(settings[selectedSetting])}
          </Col>
        </Row>
      </Modal.Body>
      {/* <Modal.Footer>
        <Button variant="secondary" onClick={() => setShow(false)}>
          Close
        </Button>
        <Button
          onClick={() => alert("Update logic goes here")}
          variant="primary"
        >
          Update
        </Button>
      </Modal.Footer> */}
    </Modal>
  );
};

export default withAPIRequest(ConfigurationsModal);

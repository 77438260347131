import React, { useState } from "react";
import { Button, Card, Col, Row, Badge, Spinner } from "react-bootstrap";
import Lottie from "lottie-react";
import noSearchAnimation from "../../assets/lottie/../../assets/lottie/not_found_lottie_delhi_diocese_theme.json";
import ChooseAnnulmentTypeModal from "../../modals/annulment/ChooseAnnulmentTypeModal";
import AddExternalAnnulmentDetailsModal from "../../modals/annulment/AddExternalAnnulmentDetailsModal";
import AddAnnulmentDetailsModal from "../../modals/annulment/AddAnnulmentDetailsModal";
import loadingAnimation from "../../assets/lottie/loading_animation_delhi_theme.json";
import { API, AXIOS_METHOD_TYPES } from "../../constants/api";
import { withAPIRequest } from "../../HOC/withAPIRequest";
import AnnulmentInfoOffset from "../../offset-canvas/annulment/AnnulmentInfoOffset";
import GenerateAnnulmentCertificateModal from "../../modals/annulment/GenerateAnnulmentCertificateModal";
import AnnulmentLinkCertificateModal from "../../modals/annulment/AnnulmentLinkCertificateModal";
import { useSelector } from "react-redux";

const AnnulmentDetails = ({
  basicDetails,
  marriageDetails,
  annulmentDetails,
  getFamilyMemberDetails,
  familyDetails,
  commonAPIRequest,
}) => {
  const [chooseAnnulmentTypeModalVisible, setChooseAnnulmentTypeModalVisible] =
    useState(false);

  const [externalAnnulmentModalVisible, setExternalAnnulmentModalVisible] =
    useState(false);

  const [addAnnulmentDetailsModalVisible, setAddAnnulmentDetailsModalVisible] =
    useState(false);
  const [certPdf, setCertPdf] = useState();
  const [loading, setLoading] = useState(false);
  const [annulmentOffsetModalVisible, setAnnulmentOffsetModalVisible] =
    useState(false);
  const [generateAnnulmentModal, setGenerateAnnulmentModal] = useState(false);
  const [currentAnnulment, setCurrentAnnulment] = useState();
  const { churchDetails } = useSelector((state) => state?.church);
  const [annulmentLinkCertModal, setAnnulmentLinkCertModal] = useState(false);
  // call generate pdf certificate api for annulment start
  const [loading2, setLoading2] = useState(false);
  // call generate pdf certificate api for annulment start

  const callGetAnnulmentCertificateApi = () => {
    let serviceParams = {
      api: `${API.GENERATE_ANNULMENT_CERTIFICATE_API}?annulmentId=${annulmentDetails?.annulmentId}`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          setCertPdf(result?.payload);
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  // call generate pdf certificate api for annulment end

  // call generate pdf certificate api for annulment end

  const callGetAnnulmentDetailsForLink = (id) => {
    let serviceParams = {
      api: `${API.ANNULMENT_DETAILS}?annulmentId=${id}`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading2(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading2(false);
        try {
          // console.log("result", result.payload);
          setCurrentAnnulment(result?.payload);
          setAnnulmentLinkCertModal(true);
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading2(false);
      }
    });
  };

  const callGetAnnulmentDetailsForPreview = (id) => {
    let serviceParams = {
      api: `${API.ANNULMENT_DETAILS}?annulmentId=${id}`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          // console.log("result", result.payload);
          setCurrentAnnulment(result?.payload);
          setGenerateAnnulmentModal(true);
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  return (
    <div>
      {annulmentDetails ? (
        <Card className="card-post mt-4 ">
          <Card.Header>
            <h3 className="fw-bold text-primary">Annulment Information</h3>
            {!annulmentDetails?.extAnnulment && (
              <Button onClick={() => setAnnulmentOffsetModalVisible(true)}>
                <i className="ri-information-line"></i>
              </Button>
            )}
          </Card.Header>
          <hr className="m-0 hr" />
          <Card.Body>
            <Row>
              <Col>
                <Row>
                  <Col sm={4}>
                    <div className="my-2">
                      <p className="mb-0">
                        <strong>Annulment Done?</strong>
                      </p>
                      <p className="mb-0">Yes</p>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="my-2">
                      <p className="mb-0">
                        <strong>Date Of Annulment</strong>
                      </p>
                      <div className="d-flex align-items-center justify-content-start">
                        <p className="mb-0">
                          {" "}
                          {new Date(
                            annulmentDetails?.annulmentDate
                          ).toLocaleDateString("en-GB", {
                            day: "numeric",
                            month: "long",
                            year: "numeric",
                          })}
                        </p>
                        {!annulmentDetails?.annulmentDateAccurate && (
                          <Badge
                            style={{ padding: 5 }}
                            className="ms-2 fw-bold"
                            bg="warning"
                          >
                            Uncertain
                          </Badge>
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="my-2">
                      <p className="mb-0">
                        <strong>Annulment ID</strong>
                      </p>
                      <p className="mb-0">{annulmentDetails?.referenceNum}</p>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="my-2">
                      <p className="mb-0">
                        <strong>External Marriage</strong>
                      </p>
                      <p className="mb-0">
                        {annulmentDetails?.extAnnulment ? "Yes" : "No"}
                      </p>
                    </div>
                  </Col>
                  {annulmentDetails?.extAnnulmentChurch && (
                    <Col sm={4}>
                      <div className="my-2">
                        <p className="mb-0">
                          <strong>External Marriage Church</strong>
                        </p>
                        <p className="mb-0">
                          {annulmentDetails?.extAnnulmentChurch}
                        </p>
                      </div>
                    </Col>
                  )}
                  {annulmentDetails?.extAnnulmentCity && (
                    <Col sm={4}>
                      <div className="my-2">
                        <p className="mb-0">
                          <strong>External Marriage City</strong>
                        </p>
                        <p className="mb-0">
                          {annulmentDetails?.extAnnulmentCity}
                        </p>
                      </div>
                    </Col>
                  )}
                  <Col sm={4}>
                    <div className="my-2">
                      <p className="mb-0">
                        <strong>Spouse Name</strong>
                      </p>
                      {basicDetails?.gender === "Male" ? (
                        <p className="mb-0">
                          {annulmentDetails?.brideFirstName}{" "}
                          {annulmentDetails?.brideLastName}
                        </p>
                      ) : (
                        <p className="mb-0">
                          {annulmentDetails?.groomFirstName}{" "}
                          {annulmentDetails?.groomLastName}
                        </p>
                      )}
                    </div>
                  </Col>
                </Row>
                {!annulmentDetails?.extAnnulment && (
                  <div>
                    <hr className="hr" />
                    <div className="text-center m-4">
                      <Card.Title className="text-dark">
                        Generate Marriage Certificate for{" "}
                        {basicDetails?.firstName} {basicDetails?.middleName}{" "}
                        {basicDetails?.lastName}
                      </Card.Title>
                      <Card.Text>
                        Please click on Generate for generating marriage
                        certificate copy for{" "}
                        <span className="fw-bold text-primary">
                          {" "}
                          {basicDetails?.firstName} {basicDetails?.middleName}{" "}
                          {basicDetails?.lastName}
                        </span>
                      </Card.Text>

                      <Button
                        disabled={annulmentDetails?.extAnnulment}
                        variant="primary"
                        onClick={() => {
                          callGetAnnulmentDetailsForLink(
                            annulmentDetails?.annulmentId
                          );
                        }}
                      >
                        {loading2 ? (
                          <span className="d-flex align-items-center">
                            <Spinner
                              className="me-2"
                              animation="border"
                              variant="light"
                              size="sm"
                            />
                            Generating...
                          </span>
                        ) : (
                          <div className="d-flex align-items-center justify-content-center">
                            <i className="ri-download-2-fill me-2"></i>
                            Download Pdf
                          </div>
                        )}
                      </Button>

                      {/* <Button
                        variant="primary"
                        className="ms-2"
                        onClick={() => {
                          callGetAnnulmentDetailsForPreview(
                            annulmentDetails?.annulmentId
                          );
                        }}
                      >
                        {loading ? (
                          <Spinner animation="border" variant="light" />
                        ) : (
                          <i className="ri-eye-line"></i>
                        )}
                      </Button> */}
                      {/* {loading ? (
                        <div className="justify-content-center align-items-center d-flex">
                          <Lottie
                            style={{ width: 100, height: 100 }}
                            animationData={loadingAnimation}
                            loop={true}
                          />
                        </div>
                      ) : certPdf?.certName ? (
                        <Button
                          onClick={() => {
                            let pdfWindow = window.open("");
                            pdfWindow.document.write(
                              "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
                                encodeURI(certPdf.base64) +
                                "'></iframe>"
                            );
                          }}
                          variant="primary"
                        >
                          Print
                        </Button>
                      ) : (
                        <Button
                          onClick={callGetAnnulmentCertificateApi}
                          variant="primary"
                        >
                          Generate
                        </Button>
                      )} */}
                    </div>
                  </div>
                )}
              </Col>
            </Row>
          </Card.Body>
        </Card>
      ) : (
        <Card.Body className="text-center m-4">
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="text-center"
            >
              <Lottie
                style={{ width: 150, height: 150 }}
                animationData={noSearchAnimation}
                loop={true}
              />
            </div>
          </div>
          <Card.Title className="text-dark">
            No Annulment Details Found
          </Card.Title>
          <Card.Text>
            Please add Annulment details for{" "}
            <span className="fw-bold text-primary">
              {basicDetails?.firstName} {basicDetails?.middleName}{" "}
              {basicDetails?.lastName}
            </span>
            <br />
            <span className="text-danger fw-bold">
              NOTE: *Marriage is required for adding Annulment details.
            </span>
          </Card.Text>
          <Button
            disabled={
              marriageDetails?.active && !marriageDetails?.extMarriage
                ? false
                : true
            }
            variant="primary"
            onClick={() => setChooseAnnulmentTypeModalVisible(true)}
          >
            Add
          </Button>
        </Card.Body>
      )}

      <ChooseAnnulmentTypeModal
        show={chooseAnnulmentTypeModalVisible}
        setShow={setChooseAnnulmentTypeModalVisible}
        setExternalAnnulmentModalVisible={setExternalAnnulmentModalVisible}
        basicDetails={basicDetails}
        setAddAnnulmentDetailsModalVisible={setAddAnnulmentDetailsModalVisible}
      />

      <AddExternalAnnulmentDetailsModal
        show={externalAnnulmentModalVisible}
        setShow={setExternalAnnulmentModalVisible}
        basicDetails={basicDetails}
        marriageDetails={marriageDetails}
        getFamilyMemberDetails={getFamilyMemberDetails}
      />

      <AddAnnulmentDetailsModal
        show={addAnnulmentDetailsModalVisible}
        setShow={setAddAnnulmentDetailsModalVisible}
        basicDetails={basicDetails}
        familyDetails={familyDetails}
        marriageDetails={marriageDetails}
        getFamilyMemberDetails={getFamilyMemberDetails}
      />

      <AnnulmentInfoOffset
        show={annulmentOffsetModalVisible}
        setShow={setAnnulmentOffsetModalVisible}
        annulmentId={annulmentDetails?.annulmentId}
      />

      <GenerateAnnulmentCertificateModal
        setShow={setGenerateAnnulmentModal}
        show={generateAnnulmentModal}
        annulmentId={annulmentDetails?.annulmentId}
        churchDetails={churchDetails}
        currentAnnulment={currentAnnulment}
      />
      <AnnulmentLinkCertificateModal
        show={annulmentLinkCertModal}
        setShow={setAnnulmentLinkCertModal}
        annulmentId={annulmentDetails?.annulmentId}
        churchDetails={churchDetails}
        currentAnnulment={currentAnnulment}
      />
    </div>
  );
};

export default withAPIRequest(AnnulmentDetails);
